

.accsidebar {
    position: fixed;
    top: 0;
    left: 220px;
    bottom: 0;
    width: 240px;
    background-color: #f8f8f8;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .accsidebar-header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
  }
  
  .accsidebar-nav {
    padding: 20px;
  }
  
  .accsidebar-nav-link {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    color: #333;
    transition: all 0.3s ease;
    border-left: 3px solid transparent;
  }
  
  .accsidebar-nav-link:hover {
    background-color: #ddd;
    border-left: 3px solid #333;
  }
  
  .accsidebar-nav-link.active {
    background-color: #333;
    color: #fff;
    border-left: 3px solid #fff;
  }
  